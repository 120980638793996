<template>
    <ContentLoader height="250" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <circle cx="320" cy="195" r="20" />
        <circle cx="350" cy="195" r="20" />
        <circle cx="380" cy="195" r="20" />
        <rect x="0" y="200" rx="4" ry="4" width="100" height="13" />
        <rect x="0" y="180" rx="4" ry="4" width="50" height="8" />
        <rect x="0" y="160" rx="5" ry="5" width="400" height="10" />
        <rect x="0" y="0" rx="5" ry="5" width="400" height="150" />
    </ContentLoader>
</template>
<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
